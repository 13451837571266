import React from "react";
import { skills } from "../constants";

const About = () => {
  return (
    <section className="section-container flex flex-col justify-between">
      <div>
        <h1 className="head-text">
          Hello, I'm{" "}
          <span className="blue-gradient_text font-semibold drop-shadow">
            Nikola
          </span>
        </h1>
        <div className="mt-5 flex flex-col gap-3 leading-8 text-slate-500">
          A Fullstack Software Engineer passionate about crafting
          high-performance web applications. My journey began with a curiosity
          and evolved into a drive for solving complex challenges with
          innovative solutions. I’m eager for a role where I can apply my
          creative problem-solving skills to drive meaningful innovation.
        </div>
        <div className="py-14 flex flex-col">
          <h3 className="subhead-text">Skills</h3>
          <div className="mt-16 flex flex-wrap gap-24 md:gap-32 items-center justify-evenly">
            {skills.map((skill) => (
              <div key={skill.name} className="flex flex-col items-center">
                <div className="block-container w-20 h-20">
                  <div className="btn-back rounded-xl" />
                  <div className="btn-front rounded-xl flex justify-center items-center">
                    <img
                      src={skill.imageUrl}
                      alt={skill.name}
                      className="w-1/2 h-1/2 object-contain"
                    />
                  </div>
                </div>
                <p className="pt-2">{skill.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
