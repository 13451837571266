import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useScroll } from "./ScrollContext"; // Adjust the import path as necessary

const Navbar = ({ aboutRef, projectsRef }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { setScrollTo } = useScroll();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Function to explicitly close the menu
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const navigateAndScroll = (sectionName) => {
    setScrollTo(sectionName); // Set the section name for scrolling
    navigate("/"); // Navigate to the homepage
    closeMenu(); // Close the menu explicitly
  };

  return (
    <header className="header relative flex justify-between items-center p-4">
      {/* Backdrop for mobile menu */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black/30 z-10"
          onClick={closeMenu} // Use closeMenu to ensure the menu is closed when the backdrop is clicked
        ></div>
      )}

      <NavLink
        to="/"
        className="w-10 h-10 rounded-lg bg-white flex items-center justify-center font-bold shadow-md"
        onClick={closeMenu} // Ensure the menu is closed when the logo/home link is clicked
      >
        <p className="blue-gradient_text">{"</>"}</p>
      </NavLink>

      {/* Hamburger menu icon for mobile view */}
      <button className="sm:hidden z-20" onClick={toggleMenu}>
        <img src="menu.svg" alt="navigation menu" className="w-7 h-7" />
      </button>

      {/* Navigation menu */}
      <div
        className={`dropdown-menu ${
          isMenuOpen ? "dropdown-menu-visible" : ""
        } z-20`}
      >
        <div className="text-lg font-medium flex flex-col gap-4 p-4">
          <button onClick={() => navigateAndScroll("home")}>Home</button>
          <button onClick={() => navigateAndScroll("about")}>About</button>
          <button onClick={() => navigateAndScroll("projects")}>
            Projects
          </button>
        </div>
      </div>

      {/* Links for desktop view */}
      <div className="hidden sm:flex gap-7 font-medium">
        <button
          className="hover:text-blue-500"
          onClick={() => navigateAndScroll("home")}
        >
          Home
        </button>
        <button
          className="hover:text-blue-500"
          onClick={() => navigateAndScroll("about")}
        >
          About
        </button>
        <button
          className="hover:text-blue-500"
          onClick={() => navigateAndScroll("projects")}
        >
          Projects
        </button>
      </div>
    </header>
  );
};

export default Navbar;
