import React from "react";
import { socialLinks } from "../constants";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="section-container flex justify-center items-center">
      <div className="flex flex-col sm:flex-row sm:justify-between gap-6">
        <div className="flex flex-col justify-center drop-shadow">
          <h1 className="head-text ">
            <span className="blue-gradient_text">Fullstack</span> Software
            Developer👋🏻
          </h1>
          <div className="pt-2 leading-8">
            <p>
              Hi, I'm a skilled Software Engineer, crafting ideas into web apps.
              Based in Novi Sad, Serbia.📍
            </p>
          </div>
          <div className="flex gap-8 pt-5">
            {socialLinks.map((link) => (
              <Link key={link.name} to={link.link} target="_blank">
                <img
                  src={link.iconUrl}
                  alt={link.name}
                  className="w-6 h-6 object-contain"
                />
              </Link>
            ))}
          </div>
        </div>
        <div className="items-center w-full sm:w-[50%] flex justify-center mt-12 sm:pt-0">
          <div className="w-[18rem] h-[18rem] sm:w-[20rem] sm:h-[20rem] gradient-border  rounded-full overflow-hidden shadow-xl">
            <div className="relative right-4">
              <img src="profile.png" alt="portrait" className="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
