import {
  car,
  contact,
  css,
  chat,
  sql,
  shopify,
  github,
  html,
  javascript,
  linkedin,
  mongodb,
  nodejs,
  blog,
  react,
  typescript,
  next,
  summiz,
  tailwindcss,
  travel,
  movie,
  menu,
} from "../assets/icons";

export const skills = [
  {
    imageUrl: css,
    name: "CSS",
    type: "Frontend",
  },
  {
    imageUrl: github,
    name: "GitHub",
    type: "Version Control",
  },
  {
    imageUrl: html,
    name: "HTML",
    type: "Frontend",
  },
  {
    imageUrl: javascript,
    name: "JavaScript",
    type: "Frontend",
  },
  {
    imageUrl: mongodb,
    name: "MongoDB",
    type: "Database",
  },
  {
    imageUrl: sql,
    name: "SQL",
    type: "Database",
  },
  {
    imageUrl: nodejs,
    name: "Node.js",
    type: "Backend",
  },
  {
    imageUrl: react,
    name: "React",
    type: "Frontend",
  },
  {
    imageUrl: typescript,
    name: "TypeScript",
    type: "Frontend",
  },
  {
    imageUrl: shopify,
    name: "Shopify Liquid",
    type: "Backend",
  },
  {
    imageUrl: next,
    name: "Next.js",
    type: "Frontend",
  },
  {
    imageUrl: tailwindcss,
    name: "Tailwind CSS",
    type: "Frontend",
  },
];

export const nav = { iconUrl: menu };

export const socialLinks = [
  {
    name: "Contact",
    iconUrl: contact,
    link: "mailto:example@example.com?subject=Job Inquiry",
  },
  {
    name: "GitHub",
    iconUrl: github,
    link: "https://github.com/NikolaVekic",
  },
  {
    name: "LinkedIn",
    iconUrl: linkedin,
    link: "https://www.linkedin.com/in/web-nikolavekic/",
  },
];

export const projects = [
  // {
  //   iconUrl: chat,
  //   theme: "btn-back-red",
  //   name: "Messenger App",
  //   description:
  //     "Fast and secure real-time chat application, built with React and Firebase.",
  //   link: "https://github.com/NikolaVekic/messenger-app",
  // },
  // {
  //   iconUrl: blog,
  //   theme: "btn-back-green",
  //   name: "Nature Journal",
  //   description:
  //     "News website designed for wilderness education, built with React and NodeJS.",
  //   link: "https://github.com/NikolaVekic/react-blog",
  // },
  {
    iconUrl: movie,
    theme: "btn-back-pink",
    name: "Movie App",
    description:
      "Movie app using React & Tailwind, featuring API search & trending algorithm.",
    link: "https://github.com/NikolaVekic/cinema-landing-page",
    liveLink: "https://cinema-landing-page.netlify.app/",
  },
  {
    iconUrl: blog,
    theme: "btn-back-blue",
    name: "Travel Blog",
    description:
      "Blog app built with Node.js and MongoDB featuring CRUD operations and user authentication.",
    link: "https://github.com/NikolaVekic/blog-app",
  },
  {
    iconUrl: summiz,
    theme: "btn-back-black",
    name: "NexusAI",
    description:
      "Landing page for a modern AI website, build with React and Tailwind CSS.",
    link: "https://github.com/NikolaVekic/react-landing-page",
    liveLink: "https://nexusai-react-landing-page.netlify.app/",
  },
  {
    iconUrl: car,
    theme: "btn-back-yellow",
    name: "Car Rental",
    description:
      "Full-stack car rental platform built with React, Tailwind CSS, and Sanity CMS.",
    link: "https://github.com/NikolaVekic/rentcars-app",
    liveLink: "https://rentcars-project.netlify.app/",
  },
];
