import React from "react";

const CTA = () => {
  return (
    <section className="cta">
      <p className="cta-text text-black font-medium">
        Have a project in mind? <br className="sm:block hidden" />
        Lets work together!
      </p>
      <button className="btn">
        <a
          href="mailto:nikolavekic1997@gmail.com?subject=Job Inquiry"
          className="link"
        >
          Contact
        </a>
      </button>
    </section>
  );
};

export default CTA;
